@import 'bootstrap';
@import 'angle';

.form-horizontal .form-group {
  @extend .row;
}

.form-horizontal {
  .checkbox-label {
    padding-left: 1.5rem;
  }
}

.form-horizontal .input-label {
  @extend .col-form-label;
  @extend .col-xl-2;
}

.form-horizontal .input-group {
  @extend .col-xl-10;
}

h5 {
  font-weight: bold;
}

p {
  font-size: 95%;
}

.table th, .table td {
  vertical-align: middle;
}

fieldset:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

fieldset .form-group:only-child {
  margin-bottom: 0;
}

a.disabled {
  pointer-events: none;
}

.ascii {
  font-size: 108%;
}

.link-like {
  text-decoration: none;
  color: #5d9cec;
  padding: 0 1px;
  cursor: pointer;
}

.link-like:hover {
  color: #1B72E6;
}

.tooltip-inner {
  max-width: 400px;
}

.tooltip-inner > img {
  max-width: 100%;
}

.thumbnail-container {
  width: calc(1000px * 0.1);
  height: calc(800px * 0.1);
  display: inline-block;
  overflow: hidden;
  position: relative;
  background: #f9f9f9;
  cursor: pointer;

  .thumbnail {
    width: 1000px;
    height: 800px;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;

    iframe {
      width: 1000px;
      height: 800px;
      pointer-events: none;
    }
  }
}

.preview-modal .modal-dialog {
  max-width: 900px;
}

.pc-preview {
  border: 1px solid #cfdbe2;
  iframe {
    width: 100%;
    height: 600px;
  }
}

.sp-preview {
  width: 290px;
  height: 520px;
  border: 1px solid #cfdbe2;
  margin: 0 auto;
  float: none;
  iframe {
    width: 100%;
    height: 100%;
  }
}

.split-dropdown > button:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.split-dropdown > button:nth-child(2) {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: -1px;
}

.dropdown-item {
  cursor: pointer !important;
}

.badge {
  font-weight: 500;
}

.nav-item {
  cursor: pointer;
}

tr.inactive {
  background-color: #edf1f2;
  color: #909FA7;
}

select.inline-select {
  width: auto;
  margin-right: 10px;
}

.form-check {
  margin-bottom: 1.5rem;
}

.form-group, fieldset .form-group {
  margin-bottom: 1.5rem;
}

.input-group {
  width: auto;
}

.form-control.is-invalid ~ .input-group-append > .input-group-text {
  border-color: #f05050;
}

.codemirror-wrapper {
}

.react-codemirror2 {
  width: 100%;
  border: 1px solid #ddd;
}

.react-codemirror2.default-height > .CodeMirror {
  height: auto;
}

.react-codemirror2.transparent-linenumbers .CodeMirror-linenumber {
  color: transparent;
}

.form-check-label > .form-check-input {
  margin-left: 0;
}

.input-option {
  margin-left: 1.25rem;
}

.option-box-wrapper {
  margin-bottom: 5px;
}

.option-box-wrapper:last-child {
  margin-bottom: 0;
}

.btn.whirl:after {
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
}

.disappearance {
  visibility: hidden;
  position: absolute;
  top: -9999px;
}

a:not([href]):not([tabindex]) {
  color: #5d9cec;
}

.code-text {
 white-space: pre-wrap;
 border: 1px solid $gray;
 border-radius: 5px;
}

.toggler {
  cursor: pointer;
}

/* campaign list */
.campaign-overview p {
  margin-bottom: 4px;
}

.wrapper {
  height: 100%;
}

body,
html {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

#gjs {
  border: 3px solid #444;
}

/* Reset some default styling */
.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
}

.editor-row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  height: calc(100% - 42px);
}

.editor-canvas {
  flex-grow: 1;
}

.panel__right {
  flex-basis: 230px;
  position: relative;
  overflow-y: scroll;
}

.panel__top {
  padding: 0;
  width: 100%;
  display: flex;
  position: initial;
  justify-content: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(0,0,0,.2);
}

.panel__devices {
  position: initial;
}

.gjs-am-preview {
  background-size: contain;
}

.blocks {
  height: 30px;
}

.gjs-pn-btn {
  line-height: 21px !important;
}

.gjs-radio-item label {
  margin-bottom: 0;
  display: block;
}

.gjs-layer-has-exclamation {
  position: absolute;
  right: 5px;
  top: 9px;
}

.gjs-layer-exclamation {
  display: block;
  width: 100%;
  max-width: 15px;
  max-height: 7px;
  padding-left: 7px;
}

/* ツールチップがはみ出しても途切れないように */
.gjs-trt-trait .gjs-label {
  overflow: visible;
}

.gjs-tooltip {
  position: relative;
  cursor: pointer;
}
.gjs-tooltip::after {
  content: attr(data-tooltip);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
  display: inline-block;
  padding: 5px;
  white-space: nowrap;
  font-size: 0.8rem;
  line-height: 1.3;
  background: #333;
  color: #fff;
  border-radius: 3px;
  transition: 0.3s ease-in;
}
.gjs-tooltip:hover::after {
  opacity: 1;
  visibility: visible;
}

/* ツールチップを大きく表示 */
.tooltip-inner {
  max-width: 800px;
}

